// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Link from '../components/link'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Haus of Prisma',
  nakedPageSlug: '',
  pageAbstract:
    'Founded by Franz Fassbender in 1988, Prisma of Auroville, India, is a narrative of coming together – a coming together of a galaxy of talents, pioneers and beacons over the past five decades in Auroville, which has made this experimental township what it is today, a place like nowhere else in the world. Prisma has patiently documented on pages and images the evolution of this creative spirit and its manifestation in a myriad of forms. Today, Prisma has morphed into a publisher-curator-bookseller backed by the latest in technology and design platforms to articulate the story of Auroville to a global audience.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    prismadreamsCover: file(relativePath: { eq: "prismadreams.jpg" }) {
      ...max1500
    }
    prismaartsCover: file(relativePath: { eq: "prismaarts.jpg" }) {
      ...max1500
    }
    prismabooksCover: file(relativePath: { eq: "prismabooks.jpg" }) {
      ...max1500
    }
    prismadesignCover: file(relativePath: { eq: "prismadesign.jpg" }) {
      ...max1500
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper
      className="default-container"
      seoData={seoData}
      {...props}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 2rem)',
          justifyContent: 'space-between',
        }}
      >
        <main role="main">
          <div className="projects">
            <Link to="https://books.prisma.haus">
              <Img
                fluid={props.data.prismabooksCover.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
              <div className="overflow">
                <div className="ceiling">
                  <div className="super">
                    <span className="as-paragraph">01. </span>&nbsp;
                    <h3>
                      <span>
                        books, publishing, digital & accessible publications
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="floor">
                  <h2>Prisma Books</h2>
                  <p>
                    Prisma Books publishes good old-fashioned, beautifully
                    curated books on Auroville, a nebula of spiritual pursuits
                    and evolutionary living. Visit website&nbsp;&nbsp;
                    <span className="points-right">∆</span>
                  </p>
                </div>
              </div>
            </Link>
            <Link to="https://design.prisma.haus">
              <Img
                fluid={props.data.prismadesignCover.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
              <div className="overflow">
                <div className="ceiling">
                  <div className="super">
                    <span className="as-paragraph">02. </span>&nbsp;
                    <h3>
                      <span>technology, design, prototyping</span>
                    </h3>
                  </div>
                </div>
                <div className="floor">
                  <h2>Prisma Design</h2>
                  <p>
                    Prisma Design is a multiverse project set in Auroville,
                    south India, bringing together design, technology and
                    creative imagination for a luminous future. Visit
                    website&nbsp;&nbsp;
                    <span className="points-right">∆</span>
                  </p>
                </div>
              </div>
            </Link>
            <Link to="https://dreams.prisma.haus">
              <Img
                fluid={props.data.prismadreamsCover.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
              <div className="overflow">
                <div className="ceiling">
                  <div className="super">
                    <span className="as-paragraph">03. </span>&nbsp;
                    <h3>
                      <span>play, share, learn</span>
                    </h3>
                  </div>
                </div>
                <div className="floor">
                  <h2>Prisma Dreams</h2>
                  <p>
                    Prisma Dreams is a laboratory of freedom and play between
                    the past, present and future. Visit website&nbsp;&nbsp;
                    <span className="points-right">∆</span>
                  </p>
                </div>
              </div>
            </Link>
            <Link to="https://arts.prisma.haus">
              <Img
                fluid={props.data.prismaartsCover.childImageSharp.fluid}
                durationFadeIn={getRandomArbitraryInt(150, 450)}
              />
              <div className="overflow">
                <div className="ceiling">
                  <div className="super">
                    <span className="as-paragraph">04. </span>&nbsp;
                    <h3>
                      <span>art seller, exhibition, products</span>
                    </h3>
                  </div>
                </div>
                <div className="floor">
                  <h2>Prisma Arts</h2>
                  <p>
                    Prisma Art curates and offers inspirational contemporary art
                    from Auroville, India and beyond for art lovers and
                    collectors across the globe. Visit website&nbsp;&nbsp;
                    <span className="points-right">∆</span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </main>
        <header style={{ paddingTop: '4rem' }}>
          <h1>Haus of Prisma</h1>
          <p>
            <strong>Books • design • Art • dreams</strong>
          </p>
          <p>
            Founded by Franz Fassbender in 1988, Prisma of Auroville, India, is
            a story of coming together. A coming together of unique talents,
            stamina and creation over the past five decades which makes
            Auroville what it is today, a place like nowhere else in the world.
          </p>
          <p
            style={{
              marginBottom: 0,
            }}
          >
            Prisma has patiently documented with words and pictures this
            evolving, creative spirit and its manifest in a myriad of forms for
            the past three decades. With human-centric technology and a luminous
            sensibility, it now offers the many faceted wonders of Auroville to
            a global following.
          </p>
        </header>
      </div>
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
